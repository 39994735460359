import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../components/common/link';
import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';
import { resolve } from '../helpers/urls';

import './index.scss';

const bem = create('page-index');

const IndexPage = () => {
  const {
    allMdx: { edges },
  } = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            slug
            id
            frontmatter {
              variant
            }
          }
        }
      }
    }
  `);

  const workAmount = edges.filter((edge) => edge.node.frontmatter.variant === 'work').length;
  const experimentsAmount = edges.filter((edge) => edge.node.frontmatter.variant === 'experiments').length;
  const articlesAmount = edges.filter((edge) => edge.node.frontmatter.variant === 'articles').length;

  const { t } = useTranslation();

  return (
    <Metadata
      title={t('Home')}
      description={t('Portfolio homepage of frontend developer Yves Rijckaert')}
    >
      <article className={bem()}>
        <div className={bem('container')}>
          <h1 className={bem('headline')}>
            <Link to={resolve('index')} className={bem('link')} rel="index">
              {t('Yves Rijckaert')}
            </Link>
          </h1>
          <nav className={bem('navigation')}>
            <ul>
              <li className={bem('list-item')}>
                <p>
                  <Link className={bem('link')} to={resolve('work:list')}>
                    {t('Client work')}
                    <sup className={bem('sup')}>
                      (
                      {workAmount}
                      )
                    </sup>
                  </Link>
                </p>
              </li>
              <li className={bem('list-item')}>
                <Link className={bem('link')} to={resolve('experiments')}>
                  {t('Experiments')}
                  <sup className={bem('sup')}>
                    (
                    {experimentsAmount}
                    )
                  </sup>
                </Link>
              </li>
              <li className={bem('list-item')}>
                <Link className={bem('link')} to={resolve('articles:list')}>
                  {t('Articles')}
                  <sup className={bem('sup')}>
                    (
                    {articlesAmount}
                    )
                  </sup>
                </Link>
              </li>
              <li className={bem('list-item')}>
                <Link className={bem('link')} to={resolve('about')}>{t('About & Contact')}</Link>
              </li>
            </ul>
          </nav>
        </div>
      </article>
    </Metadata>
  );
};

export default IndexPage;
